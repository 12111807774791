@media (max-width: 767px) {
    body > .container { overflow: hidden; } /* fix */


}

root {
    display: block;

}

html {
    height: 100%;
    width:100%;

}

body {
    width:100%;
    height: 100%;
    margin: auto;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 300;
    font-size: 1.6em;
    background-color: black;

}




.container-full {
    padding-top: 60px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;

}

.nopadding-left {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.nopadding-right {
    padding-right: 0 !important;
    margin-right: 0 !important;
}

.spacer {
    height: 50px;
}

.opacity-hidden {
    opacity: 0;
}

.small-spacer {
    height: 25px;
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;

}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;

}

a div {
    cursor: pointer;
}

.video-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;

    &:before {
        display: block;

        width: 100px;
        height: 100px;

        position: absolute;
        top: 50%;    left: 50%;

        margin: -50px 0 0 -50px;

        border-radius: 50%;

        text-align: center;
        font: 100px 'FontAwesome';
        color: white;

        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;

        -webkit-transition: -webkit-transform 0.3s ease, opacity 0.3s ease;
        transition: transform 0.3s ease, opacity 0.3s ease;

    }

}

.gallery-preview {
    &:hover .gallery-preview-overlay {
        transform: scale(1.1);

    }
}

.gallery-preview-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 585px;

    -webkit-transition: -webkit-transform 0.3s ease, opacity 0.3s ease;
    transition: transform 0.3s ease, opacity 0.3s ease;

   button {
       position: absolute;
       left: 50%;
       top: 50%;
       transform: translate(-50%, -50%);

       padding: 12px 17px;
       background-color: $gold;
       background: url(/img/elements/titles-bg.jpg);
       background-size: cover;
       border: 0px;
       color: black;
       font-size: 1.6em;
       font-weight: 300;
   }

}

.youtube-link {
    &:hover .video-overlay:before {
        transform: scale(1);
    }
}

.footer {
    &-title {
        color: $light-gold;
        font-size: 1.6em;
        font-weight: 300;

    }

    .bottom-line {
        margin-top: 30px;
    }

    .partner-logo {
        margin-bottom: 20px;
        padding: 0px;
    }

    &-copyright {

        color: white;
        font-size: 0.8em;
        font-weight: 300;
        position: absolute;
        right: 0px;
        margin-bottom: 20px;

        @include respond-to(xs) {
            position: relative;
        }


        .ca-logo {
            margin-left: 20px;
            display: inline-block;
            width: 100px;
            cursor: pointer;
        }
    }

    .booking-text {

        color: $gold;


        .booking-title {
            font-size: 2em;
            font-weight: 600;
        }

        .booking-button button {

            padding: 12px 17px;
            background-color: $gold;
            background: url(/img/elements/titles-bg.jpg);
            background-size: cover;
            border: 0px;
            color: black;
            margin-top: 15px;
        }
    }

    .side-oceneni {
        color: $gold;

        font-size: 1.3em;
        font-weight: 300;
        font-style: italic;

        h3 {
            font-size: 1.3em;
            font-weight: 600;
        }

        img {
            float: right;
            margin-right: 35px;
        }
    }

    .side-promo {

        .Titlebox__content {
            width: 80%;
        }

    }

    .footer-items {
        margin-top: 30px;
        margin-bottom: 40px;
    }
}

.relative {
    position: relative;
}

.primary-color-text {
    color: $gold;

}

.bigger-text {
    font-size: 1.3em;
}

.bold-text {
    font-weight: 400;
}

.left-padded {
    padding-left: 15px;
}

.submit-button {
    padding: 12px 40px;
    background-color: $gold;
    background: url(/img/elements/titles-bg.jpg);
    background-size: cover;
    border: 0px;
    color: black;
    margin-top: 15px;
    font-size: 1.2em;

    &.disabled {
        color: gray;

    }
}

.gallery-items {
    margin-top: 5px;
    .photo {
        margin: 0 5px;
        border: 1px solid darken($gold, 30%);
    }
}


/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.back-button {
    background-color: $gold;
    background: url(/img/elements/titles-bg.jpg);
    background-size: cover;
    padding: 10px 38px;
    font-size: 1.3em;
    float: right;
    color: black;
}

.social-box-top {

    position: absolute;
    top: 70px;
    right: 1%;


    @include respond-to(1145px) {
        right: 15%;
    }
    .social-icon {
        display: inline-block;
        margin: 3px 5px;

        @include respond-to(370px) {
            img {
                height: 35px;
            }
        }
    }

    .account-btn-container {

        display: inline-block;
        position: relative;

        .account-icon {
            background-color: $gold;
            background: url(/img/elements/titles-bg.jpg);
            background-size: cover;
            width: 48px;
            height: 48px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            cursor: pointer;

            img {
                height: 48px;
                width: 48px;
                border-radius: 50%;
            }

            @include respond-to(370px) {
                height: 35px;
                width: 35px;
                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }

        .account-title {
            color: $gold;
            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        }

        .account-box {

            display: none;
            position: absolute;
            background-color: rgba(4, 4, 4, 0.92);
            color: #e8cfa6;
            border: 1px solid #635426;
            border-radius: 0px 0px 10px 10px;
            right: -55px;
            top: 61px;
            padding: 15px 50px 15px 20px;

            ul {
                padding: 0px;
                list-style: none;

                li {

                    cursor: pointer;

                    a {
                        padding: 5px 0px;
                    }
                }
            }

            .top-carret {
                display: none;
                position:absolute;
                top: -15px;
                right: 73px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 15px 10px;
                border-color: transparent transparent rgba(4, 4, 4, 0.92) transparent;

            }


        }
    }


}

input[type=text], input[type=password], input[type=email], input[type=datetime-local], select {
    background-color: $gold !important;
    color: black;
    border: 1px solid $gold !important;
}

.float-right {
    float: right;
}

.list-divider {
    border-bottom: 1px dotted gray;
    margin: 5px;
}


.float-login {
    width: 350px;
}

.text-404 {
    text-align: center;
    margin: 30px 0px;
    color: white;
}

.container-bg {
    @include respond-to(830px) {
        margin-top: 55px;
    }
}

.cover-bg {
    @include respond-to(1400px) {
        margin-top: 55px;
    }
}