.page-content {
    position: relative;


}

@media (max-width: 992px) {
    .padded-container {
        padding-left: 0px !important;
    }


}


@media (min-width: 992px) {
    .padded-container {
        padding-left: 50px !important;
    }
}


.padded-container {
    padding-left: 100px;

    .heading {
        margin-top: 30px;
    }
}


.page-body {
    margin-top: 30px;

    h3 {
        color: $red;
        font-weight: 400;
        margin-top: 0px;


    }
}

.other-page-header {
    //background: url(/img/pages/other-page-top-bg.jpg) no-repeat top center;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    min-height: 291px;
    position: relative;

    .logo {
        max-width: 290px;
        width: 100%;
    }

    .page-title-container {
        position: absolute;
        bottom: 0px;


        .page-title {

            padding: 12px 0px 12px 20px;
            background-color: $gold;
            background: url(/img/elements/titles-bg.jpg);
            background-size: cover;
            border: 0px;
            color: black;
            margin-top: 15px;

            width: 290px;

            font-weight: 300;


            h1 {
                margin:0px;
                font-size: 1.8em;
                font-weight: 300;
            }

            @include respond-to(322px) {
                width: 89%;
            }
        }
    }

}

.simple-page {
    color: $light-gold;
}

.profile-page {
    color: white;
    .header-text {


        padding-left: 15px;
        font-size: 1.1em;



    }

    .basic-info {

        h2 {
            padding-left: 0.5em;
            margin-bottom: 1em;

        }

        .highlighted-text {
            padding-left: 0.8em;
            margin-bottom: 0.5em;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            padding-left: 1em;
            text-indent: -0.9em;
            line-height: 1.2em;
            padding-bottom: 1em;
            font-size: 0.95em;
        }

        li::before {
            content: "● ";
            color: $gold; /* or whatever color you prefer */

        }


    }

    .profile-text {
        font-size: 1.5em;

        @include respond-to(620px) {
            font-size: 1.2em;
        }

        @include respond-to(500px) {
            font-size: 1em;
        }
    }


}

.bottom-smoke {
    position: absolute;
    bottom: 0px;
    width: 100%;

    background: url(/img/pages/top-smoke-bg.png) repeat-x bottom center;
    background-size: cover;
    min-height: 585px;
    pointer-events: none;
    background-position-x: 0px;



}

.side-info {

    padding-left: 60px;

    @include respond-to(md) {
        padding-left: 30px;
    }

    @include respond-to(500px) {
        padding-left: 0px;
    }



    .booking-text {

        padding-top: 37px;
        color: $gold;


        .booking-title {
            font-size: 2em;
            font-weight: 600;
        }

        .booking-button button {

            padding: 12px 17px;
            background-color: $gold;
            background: url(/img/elements/titles-bg.jpg);
            background-size: cover;
            border: 0px;
            color: black;
            margin-top: 15px;
            cursor: pointer;
        }

    }

    .side-oceneni {

        color: $gold;

        font-size: 1.4em;
        font-weight: 300;
        font-style: italic;
        margin-top: 40px;
        h3 {
            font-size: 1.5em;
            font-weight: 600;
        }

        img {
            float: right;
        }
    }

    .side-oceneni-full {

        position: relative;

        margin-top: 50px;

        .oceneni-text {
            position: absolute;
            top: 3px;
            left: 23px;
            color: black;
            font-style: italic;
            font-size: 1.1em;
            font-weight: 300;
            width: 60%;
            border-bottom: 1px solid black;

            h3 {

                font-weight: 600;
                font-style: italic;
                font-size: 1.2em;
                margin-bottom: 5px;
            }
        }


    }



    .side-promo {
        margin-top: 55px;
        color: black;
    }

}

.news-page {
    color: white;

    .archive-button {
        color: black;
        position: absolute;
        background-color: $gold;
        background: url(/img/elements/titles-bg.jpg);
        background-size: cover;
        padding: 7px 40px 7px 40px;
        font-weight: 300;
        font-size: 1.4em;
        right: 15px;
        bottom: -46px;
        cursor: pointer;
    }
}

.concerts-page,.tour-page {
    color: white;

    .archive-button {
        color: black;
        position: absolute;
        background-color: $gold;
        background: url(/img/elements/titles-bg.jpg);
        background-size: cover;
        padding: 7px 40px 7px 40px;
        font-weight: 300;
        font-size: 1.4em;
        right: 15px;
        bottom: -46px;
        cursor: pointer;
    }
}


.media-page {
    .press-kit-button {
        color: black;

        background-color: $gold;
        background: url(/img/elements/titles-bg.jpg);
        background-size: cover;
        padding: 7px 40px 7px 40px;
        font-weight: 300;
        font-size: 1.4em;

        display: inline-block;
        cursor: pointer;
    }
}

.contact-page {

    .booking-text {

        padding-top: 27px;
        padding-left: 20px;
        color: $gold;


        .booking-title {
            font-size: 2em;
            font-weight: 600;
        }

        .booking-button button {

            padding: 12px 17px;
            background-color: $gold;
            background: url(/img/elements/titles-bg.jpg);
            background-size: cover;
            border: 0px;
            color: black;
            margin-top: 15px;
            cursor: pointer;
        }

    }

    .reservation {
        color: $gold;
        .title h2{
            text-align: center;
            font-weight: 600;
            font-size: 2.1em;
        }

        .subtitle {
            text-align: center;
            font-size: 1.2em;
        }

        .reservation-form-container {


            .g-recaptcha {
                float: right;
            }



            .form-inline {

                label {
                    font-size: 1.2em;
                    font-size: 1.2em;
                    margin: 0.5em 0;
                    font-weight: 300;
                    color: $gold;
                }



                input {
                    width: 100%;
                    background-color: $gold;
                    color: black;
                    border: 0px;
                }

                textarea {
                    width: 100%;
                    background-color: $gold;
                    border: 0px;
                    color: black;
                }

            }

            .form-group {
                margin-bottom: 0px;
            }

            .text-inputs {
                .title {
                    font-size: 1.2em;
                    margin-top: 30px;
                }

                .submit-button {
                    float: right;
                    cursor: pointer;

                }
            }

        }
    }
}

.concert-detail {

    .Titlebox__title {
        color: black;
        font-size: 1.2em;
    }

    .Titlebox__content {
        padding-top: 20px;
    }
    .row {
        margin-bottom: 20px;
    }
    .title {
        color: $gold;
        font-weight: 400;
        font-size: 1.2em;
    }

    .description {
        color: $light-gold;
        font-size: 1.1em;
    }
}

.gallery-box {
    padding-top: 10px;
    padding-left: 14px;
    padding-right: 14px;
}

.gallery-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 1px;
    cursor: pointer;

    .video-overlay {

        transition: transform 0.4s ease 0s;
    }



    img {
        display: block;
        position: relative;
        transition: transform 0.4s ease 0s;
        width: 100%;
        cursor: pointer;
    }

    &:hover {

        img {

        }

        .gallery-overlay {
            background: none repeat scroll 0 0 rgba(0, 0, 0, 0.70);


                opacity: 1;


        }

        .video-overlay {
            transform: scale(1.15);
        }
    }


}

.gallery-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    color: white;
    font-weight: 400;
    font-size: 1.1em;

    text-align: center;
    vertical-align: middle;
    top: 71%;
    padding-top: 4%;

    background: rgba(0, 0, 0, 0);
    @include prefixed(transition, background-color 0.3s ease);
    @include prefixed(transition, opacity 0.3s ease);



}

.gallery-detail-box {
    padding-top: 10px;
    padding-left: 14px;
    padding-right: 14px;

    .gallery-item-wrapper {
        padding: 1px;
    }
}


.gallery-item-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100%;

    img {
        display: block;
        position: relative;
        transition: transform 0.4s ease 0s;
        width: 100%;
    }

    &:hover {

        img {

            transform: scale(1.15);
        }

        .gallery-item-overlay {
            background: none repeat scroll 0 0 rgba(20, 22, 23, 0.2);

            &:before {
                opacity: 1;
                transform: scale(1.0);
            }
        }
    }
}


.radio-select {

    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 150px;

    @include respond-to(xs) {
        margin-left: 0px;
    }



    ul{
        list-style: none;
        height: 130px;
        width: 100%;
        margin: 0;
        padding: 0;
    }


    ul li{
        color: $gold;
        display: block;
        position: relative;
        float: left;
        width: 100%;
        height: 37px;

        @include respond-to(480px) {
            height: 60px;
        }

    }



    ul li input[type=radio]{
        position: absolute;
        visibility: hidden;
    }

    ul li label{
        display: block;
        position: relative;
        font-weight: 300;
        font-size: 1.15em;
        padding: 2px 16px 25px 58px;
        margin: 4px auto;
        height: 24px;
        z-index: 9;
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
    }

    ul li:hover label{
        color: $gold;
    }

    ul li .check{
        display: block;
        position: absolute;
        border: 5px solid $gold;
        border-radius: 100%;
        height: 26px;
        width: 26px;
        top: 6px;
        left: 20px;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
    }

    ul li:hover .check {
        border: 5px solid $gold;
    }

    ul li .check::before {
        display: block;
        position: absolute;
        content: '';
        border-radius: 100%;
        height: 10px;
        width: 10px;
        top: 3px;
        left: 3px;
        margin: auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear;
    }

    input[type=radio]:checked ~ .check {
        border: 5px solid $light-gold;
    }

    input[type=radio]:checked ~ .check::before{
        background: $light-gold;
    }

    input[type=radio]:checked ~ label{
        color: $light-gold;
    }

    &.horizontal {

        margin: 20px 0px;

        ul {
            height: 45px;
        }
        ul li {
            width: initial;
        }
    }
}

.gallery-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: rgba(0, 0, 0, 0);
    @include prefixed(transition, background-color 0.3s ease);

    &:before {
        display: block;

        width: 46px;
        height: 46px;

        position: absolute;
        top: 50%;
        left: 50%;

        margin: -22px 0 0 -25px;

        border-radius: 5%;
        background: $gold;

        text-align: center;
        font: 14px/46px 'FontAwesome';
        color: #fff;

        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;

        -webkit-transition: -webkit-transform 0.3s ease, opacity 0.3s ease;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

}

.eshop-page {
    .container {

    color: $light-gold;
    }
}

.concert-tag {
    padding: 2px 5px;
    background-color: #e60000;
    text-align: center;
    position: absolute;
    right: -11px;
    bottom: -1px;
    font-size: 0.7em;
    font-weight: 600;
    transform: rotate(-9deg);
}