/* Icon Float Away */
@include keyframes(#{$nameSpace}-icon-float-away) {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		@include prefixed(transform, translateY(-1em));
	}
}

/* Icon Float Away */
@mixin icon-float-away {
	@include hacks();
	position: relative;
	padding-right: #{$spaceBetweenTextAndArrows};

	&:before,
	&:after {
		content: "\f055";
		position: absolute;
		right: 1em;
		padding: 0 1px;
		font-family: FontAwesome;
	}

	&:after {
		opacity: 0;
		@include prefixed(animation-duration, #{$slowDuration});
		@include prefixed(animation-fill-mode, forwards);
	}

	&:hover,
	&:focus,
	&:active {

		&:after {
			@include prefixed(animation-name, #{$nameSpace}-icon-float-away);
			@include prefixed(animation-timing-function, ease-out);
		}
	}
}
