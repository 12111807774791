/* Wobble Bottom */
@include keyframes(#{$nameSpace}-wobble-bottom) {
	16.65% {
		@include prefixed(transform, skew(-12deg));
	}

	33.3% {
		@include prefixed(transform, skew(10deg));
	}

	49.95% {
		@include prefixed(transform, skew(-6deg));
	}

	66.6% {
		@include prefixed(transform, skew(4deg));
	}

	83.25% {
		@include prefixed(transform, skew(-2deg));
	}

	100% {
		@include prefixed(transform, skew(0));
	}
}

@mixin wobble-bottom {
	@include hacks();
	@include prefixed(transform-origin, 100% 0);

	&:hover,
	&:focus,
	&:active {
		@include prefixed(animation-name, #{$nameSpace}-wobble-bottom);
		@include prefixed(animation-duration, 1s);
		@include prefixed(animation-timing-function, ease-in-out);
		@include prefixed(animation-iteration-count, 1);
	}
}
