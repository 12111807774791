// prefix declarations
@mixin prefixed($property, $value) {

    -webkit-#{$property}: #{$value};
    -moz-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
    -o-#{$property}: #{$value};
    #{$property}: #{$value};
}

@mixin linx($link, $visit, $hover, $active, $hoverDecoration: underline) {
    a {
        color: $link;

        &:visited {
            color: $visit;
        }
        &:hover {
            color: $hover;
            text-decoration: $hoverDecoration;
        }
        &:active {
            color: $active;
        }
    }
}

@mixin centerer($horizontal: true, $vertical: true) {
    position: absolute;
    width: 100%;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}