.registration-form {
    .reg-type {
        text-align: center;
        label {
            margin-left: 20px;
            margin-right: 20px;
        }
        height: 69px;
    }

    .company-info-group {
        display: none;
    }
}

.login-col {
    border-right: 1px solid $lightgray;
}
.login-form {
    margin-top: 10px;
    margin-left: -2px;
    margin-right: 2px;
}

.login-reg-prompt {

    text-align: center;
    
    .sign-up-title {
        color: $darkgray;
        font-size: 1.3em;
        margin-bottom: 40px;
    }
}