/* Reveal */
@mixin reveal {
	$duration: #{$fastDuration};

	@include hacks();
	position: relative;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-color: #{$activeColor};
		border-style: solid;
		border-width: 0;
		@include prefixed(transition-property, border-width);
		@include prefixed(transition-duration, #{$duration});
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		&:before {
			@include prefixed(transform, translateY(0));
			border-width: 4px;
		}
	}
}
