/* Shadow */
@mixin shadow {
	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);
	@include prefixed(transition-property, box-shadow);

	&:hover,
	&:focus,
	&:active {
		box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	}
}
