/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    ::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }

    ::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    ::-webkit-scrollbar-track {
        background: $background-color;
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;

@mixin respond-to($media) {
    @if $media == xs {
        @media only screen and (max-width: $break-sm - 1) { @content; }
    }
    @else if $media == sm {
        @media only screen and (min-width: $break-sm ) and (max-width: $break-md - 1) { @content; }
    }
    @else if $media == md {
        @media only screen and (min-width: $break-md) and (max-width: $break-lg - 1) { @content; }
    }
    @else if $media == lg {
        @media only screen and (min-width: $break-lg) { @content; }
    }
    @else {
        @media only screen and (max-width: $media) { @content; }
    }
}