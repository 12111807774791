.checkout-header-row {
    margin-bottom: 20px;

    .checkout-progress {
        padding-top: 0px;
        padding-bottom: 10px;
        background-color: black;
        text-align: center;
        vertical-align: middle;

        &.checkout-progress-lines {
            border-left: 2px solid $gold;
            border-right: 2px solid $gold;

            @include respond-to(sm) {
                border-left: 0px;
                border-right: 0px;
            }
        }



        &.active {

           // @include prefixed('box-shadow', 0px 4px 0px 0px $red);


            background: rgba(0,0,0,1);
            background: -moz-linear-gradient(top, rgba(0,0,0,1) 66%, rgba(11,10,6,1) 68%, rgba(194,169,96,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(66%, rgba(0,0,0,1)), color-stop(68%, rgba(11,10,6,1)), color-stop(100%, rgba(194,169,96,1)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,1) 66%, rgba(11,10,6,1) 68%, rgba(194,169,96,1) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,1) 66%, rgba(11,10,6,1) 68%, rgba(194,169,96,1) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,1) 66%, rgba(11,10,6,1) 68%, rgba(194,169,96,1) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,1) 66%, rgba(11,10,6,1) 68%, rgba(194,169,96,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#c2a960', GradientType=0 );

        }

        .progress-num {
            font-weight: bold;
            font-size: 2.2em;
            top: 6px;
            position: relative;
            color: $darkgray;
            margin-right: 10px;
        }
    }
}

.checkout-btn-row {
    margin-top: 20px;
    .checkout-continue {
        float: right;
    }

}

.shipping-method-row {
    margin-bottom: 50px;
    h4 {
        margin-bottom: 20px;
    }
}

.no-method {
    text-align: center;
    color: $darkgray;
    font-size: 0.9em;
}
.method-select {

    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid $lightgray;
        margin-bottom: 5px;
        padding-bottom: 5px;
    }

    .method-name {
        display: inline-block;

    }

    .method-note {
        font-size: 0.8em;
        color: $darkgray;
        margin-left: 22px;
    }

    .method-price {
        display: inline-block;
        float: right;
    }
}

#checkout-summary {



    .header-info {
        font-size: 0.90em;

        &>div {


            &:not(:last-child) {
                border-right: 1px solid $lightgray;
                min-height: 250px;
            }
        }


        h4.title {

            margin-bottom: 10px;

        }

        .shipping-method {
            margin-bottom: 40px;

        }
        .shipping-address {
            margin-bottom: 20px;
        }

        .strong {
            font-weight: 400;
        }

        .company-info {
            margin-bottom: 10px;
        }


    }

}

#item-list {
    h4 {
        margin-top: 40px;
        font-size: 1.1em;
    }
}
#checkout-item-table {
    margin-top: 20px;
    width: 100%;


    thead {

        th {
            font-weight: 600;
            font-size: 0.9em;
            border-bottom: 2px solid $lightgray;

        }
    }

    tbody {

        font-size: 0.9em;
        &:before {
            content: "-";
            display: block;
            line-height: 0.2em;
            color: transparent;
        }

        td {
            border-bottom: 1px solid $lightgray;
            padding: 3px 0px;
        }
    }

    .img-td {
        width: 100px;
    }
    .cart-img {
        max-width: 90px;
        max-height: 50px;
    }
}

#checkout-summary-footer {
    #sum-prices {
        margin-top: 10px;
        float: right;
        .sum-title {
            padding-right: 20px;
            text-align: right;
        }
        
        .total-price {
            font-weight: 400;
        }
    }
}

.order-completed {

    .title {
       // text-align: center;
        border-bottom: 1px solid $lightgray;
        color: rgba(0, 134, 0, 0.79);
        font-weight: 600;

        margin-top: 12px;
        margin-bottom: 15px;

        .text {
            font-size: 1.3em;
        }

    }

    .note {
        //text-align: center;
        margin-bottom: 20px;

    }
}

.reg-checkout-info {
    padding: 15px 20px;
    border: 1px solid $gold;
    border-radius: 10px;
    text-align: center;


    a {
        color: $light-gold;
        cursor: pointer;
        &:hover {
            color: $gold;
        }
    }


}