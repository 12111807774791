/* Trim */
@mixin trim {
	$outerBorderWidth: 4px;
	$innerBorderWidth: 4px;

	@include hacks();
	position: relative;

	&:before {
		content: '';
		position: absolute;
		border: white solid $innerBorderWidth;
		top: $outerBorderWidth;
		left: $outerBorderWidth;
		right: $outerBorderWidth;
		bottom: $outerBorderWidth;
		opacity: 0;
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, opacity);
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		opacity: 1;
	}
}
