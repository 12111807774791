.Titlebox {
    &__title {

        background-color: $gold;
        background: url(/img/elements/titles-bg.jpg);
        background-size: cover;
        display: inline-block;
        padding: 10px 30px 10px 15px;
        font-size: 1.4em;
        font-weight: 300;
    }

    &__content {

        border-top: 3px solid $gold;
        color: white;
        position: relative;
    }

    &--no-stripe {
        &>.Titlebox__content {
            border-top: 0px !important;
        }
    }

    &--small-title {
        &>.Titlebox__title {
            font-size: 1.1em;
        }
    }

    &--smaller-title {
        &>.Titlebox__title {
            font-size: 1em;
            padding: 5px 25px 5px 10px;
        }
    }
}

.CalendarEntry {

    font-weight: 300;
    padding: 0px 0px 7px 0px;
    border-bottom: 1px solid darken($gold, 20%);
    display: flex;

    &--red-stripe {
        .CalendarEntry__rightSide {
            border-left: 2px solid red;
        }
    }

    &--gold-stripe {
        .CalendarEntry__rightSide {
            border-left: 2px solid $gold;
        }
    }

    &--has-detail-button {

        &:hover {
            .CalendarEntry__detailButton {
                opacity: 1;
            }
            background-color: rgba(255, 255, 255, 0.43);
            color: black;
        }
    }

    &__leftSide {


        margin-right: 15px;
        padding-left: 5px;
        flex-basis: 14%;
        position: relative;
    }

    &__rightSide {



        padding-left: 12px;
        padding-bottom: 7px;
        vertical-align: top;
        margin-top: 9px;
        position: relative;
        width: 100%;
    }

    &__date {
        font-size: 1.4em;
    }

    &__year {
        font-size: 0.9em;
        margin-top: -6px;
    }

    &__time {
        font-size: 0.8em;
        margin-top: 3px;
    }

    &__poster {
        margin-top: 10px;
    }

    &__title {
        font-size: 2em;
        margin-top: -6px;
    }

    &__subtitle {
        font-size: 0.9em;
        margin-top: -9px;
        padding-left: 2px;
    }

    &__detailButton {
        padding: 11.8px 21px;
        background-color: $gold;
        color: black;
        font-size: 1.2em;
        float: right;
        clear: left;
        margin-right: 5px;
        opacity: 0;

        &--red {
            background-color: #e40000 !important;
            color: white;
        }

    }

    &__detailButtonVisible {
        padding: 11.8px 21px;
        background-color: $gold;
        color: black;
        font-size: 1.2em;
        float: right;
        clear: left;
        margin-right: 5px;

        &--red {
            background-color: #e40000 !important;
            color: white;
        }

    }
}

.VerticalLine {
    height: 50px;
    position: absolute;
    top: 3px;
    &--red {
        border-right: 2px solid red;
    }

    &--gold {
        border-right: 2px solid $gold;
    }


}

.FullPageLine {
    height: 1px;

    &--red {
        border-bottom: 1px solid red;
    }

    &--gold {
        border-bottom: 1px solid $gold;
    }

    &--dark-gold {
        border-bottom: 1px solid darken($gold, 50%);
    }
}

.TopLine {
    height: 1px;

    &--red {
        border-bottom: 1px solid red;
    }

    &--gold {
        border-bottom: 1px solid $gold;
    }

    &--dark-gold {
        border-bottom: 1px solid darken($gold, 50%);
    }
}