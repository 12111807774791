.item-col {

    padding: 15px;
}

.item-row {
   margin-bottom: 30px;
}

.item-wrapper {
    width: 100%;
    padding: 1px;
    @include linx(#fff,#fff,#fff,#fff, none);

}

.item-title {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: $darkgray;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    padding: 0px 5px;
    @include prefixed(transition, background-color 0.4s ease 0s);

    &.hover {
        background-color: $gold;

    }

    .item-sub-title {
        font-size: 0.7em;
    }

}

.item-image {
    width: 100%;

}

.item-img-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100%;

    img {
        display: block;
        position: relative;
        transition: transform 0.4s ease 0s;
        width: 100%;
    }

    &.hover {

        img {

        }

        .link-overlay {
            background: none repeat scroll 0 0 rgba(20, 22, 23, 0.2);

            &:before {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.link-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: rgba(0, 0, 0, 0);
    @include prefixed(transition, background-color 0.3s ease);

    &:before {
        display: block;

        width: 46px;
        height: 46px;

        position: absolute;
        top: 50%;
        left: 50%;

        margin: -22px 0 0 -25px;

        border-radius: 5%;
        background: $gold;

        text-align: center;
        font: 14px/46px 'FontAwesome';
        color: #fff;

        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;

        -webkit-transition: -webkit-transform 0.3s ease, opacity 0.3s ease;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

}

.image-div {

    padding-top: 15px;
    .image-thumb {
        cursor: pointer;
        margin-bottom: 20px;
        text-align: center;

        a {
            outline: 0;
        }


        img {
            height: 365px;
            margin: auto;

            vertical-align: middle;

        }
    }

    .image-slider {
        border-top: 1px solid #E9E9E9;
        cursor: pointer;

        img {
            margin: 5px;
            border: 1px solid $lightgray;
        }
    }
}


.price {
    font-weight: 600;
    font-size: 1.6em;
}

.price-on-request {
    font-weight: 600;
    font-size: 1.4em;
    color: black;



}

.price-note {
    color: $darkgray;
    font-size: 0.8em;
    font-weight: 400;
    position: relative;
    top: -12px;
    left: 3px;
}
.request-link {
    text-decoration: none;


}

.stock {
    font-size: 0.9em;
    font-weight: 600;


}

.tax-included {
    color: $darkgray;
    font-size: 0.8em;
    font-weight: 400;
    position: relative;
    top: -3px;
    left: 3px;
}
.info-div {
    padding-top: 25px;
    
    .title {
        font-size: 1.5em;
        color: $red;
        font-weight: 600;
    }

    hr {
        border-top: 1px solid $gold;
    }

    #add-to-cart {
        color: black;
    }
    
    .other-variants {
        margin-top: 30px;
        h2 {
            font-size: 1.2em;

            hr {
                margin-bottom: 9px;
            }
        }

        .search {
            display: inline-block;
            width: 75%;
        }

        .profile-filters {
            display: inline-block;
            margin-top: -15px;

            .filter-title {
                font-size: 0.7em;
                color: $darkgray;


            }
            ul {
                padding: 0px 0px 3px 3px;
                border-bottom: 1px solid #ddd;
                margin-top: -10px;
                li {
                    cursor: pointer;
                    display: inline-block;
                    img {
                        width: 16px;
                    }
                }
            }
        }
    }

}

.description {
    font-size: 0.92em;
}

.parameter-col {
    border-left: 1px solid $lightgray;
    min-height: 200px;
    h3 {
        font-size: 1.5em;
    }



    .parameter-rows {

        margin-top: 10px;

        .parameter-row {
            position: relative;
            width: 100%;
            font-size: 0.95em;

            .parameter-name {
                display: inline-block;
                font-weight: 600;
            }

            .parameter-value {
                display: inline-block;
            }
        }
    }



}

.detail-top-row {

    padding-bottom: 5px;
    margin-bottom: 20px;


}

.qty-input {
    position: relative;
    padding: 9px;
    margin-top: 0px;
    text-align: center;
    border: 1px solid $darkgray !important;
    width: 35px;
    margin-top: 30px;
    margin-bottom: 10px;
    background-color: black;
    color: $light-gold;
}

.cart-col {
    text-align: center;
    vertical-align: middle;
}


#variant-select {
    height: 180px;
    overflow: auto;
    position: relative;
    padding: 0;
    color: $gold;

    table {
        width: 100%;
        background-color: transparent;
        cursor: pointer;


        tr {


            display: table-row !important;
            cursor: pointer;

            &:hover {
                td {
                    color: white;
                }
            }
        }

        td {
            padding-bottom: 5px;
            @include prefixed(transition, all 0.2s);
            cursor: pointer;
        }



        td.variant-title {
            width: 65%;
            vertical-align: top;
            padding-left: 5px;
            font-size: 0.9em;
            cursor: pointer;

            &> a {

                text-decoration: none;
                cursor: pointer;


            }

            .variant-profile {
                font-size: 0.7em;
            }
        }

        td.variantProfileHidden {
            display: none;
        }

        .variant-stock {

            font-size: 0.7em;
            padding-left: 5px;
            text-align: right;
            width:1%;
            white-space:nowrap;
            transition-duration: 0.5s;
            transition-property: color, background-color;


        }

        td.variant-price {
            text-align: right;
            padding-right: 5px;
            padding-left: 15px;
            font-size: 0.9em;
            width:1%;
            white-space:nowrap;


        }

    }


}

.no-items {
    text-align: center;
    font-size: 1.7em;
    color: $darkgray;
    margin-top: 50px;
}

.illustrative-note {
    font-size: 0.8em;
    color: $darkgray;
    font-style: italic;
    text-align: center;
}

.file-row {


    display: table;
    border-bottom: 1px dotted $lightgray;
    padding: 2px 0;
    img {
        position: relative;
        top: 5px;
        width: 22px;
        display: table-cell;

    }

    a {
        display: table-cell;
        width: 100%;
        text-decoration: none !important;
        padding-left: 5px;
        font-size: 0.95em;

    }
}

.img-icon {
    height: 16px;
    width: 16px;

}

.item-not-active {
    background-color: rgba(255, 3, 0, 0.15);
    .not-active-title {
        color: $red;
        font-weight: 600;
        font-size: 1.3em;
    }
    .not-active-subtitle {
        font-size: 0.8em;

    }
}

.footer-items {
    .item-col {
        padding: 0 30px;
    }
}

.eshop-category-menu {
    list-style: none;
    text-align: center;

    &>li {
        display: inline-block;
        text-align: center;
        margin: 0 5px 10px;
        background-color: $gold;
        cursor: pointer;

        border-radius: 2px;
        color: black;
        @include prefixed(transition, all 0.3s);

        &:hover {
            background-color: $light-gold;
            box-shadow: 0 0 10px #e9edd3;
            color: black;
        }

        &.active {
            background-color: $light-gold;
        }
        a {
            color: inherit;
            display: block;
            padding: 10px 18px;
            cursor: pointer;

            &:link {
                text-decoration: inherit;
                color: inherit;
                cursor: pointer;
            }

            &:visited {
                text-decoration: inherit;
                color: inherit;
                cursor: pointer;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}


.free-shipping {
    font-weight: 400;
    font-size: 1em;
    margin: 5px 0px;
}