/* Curl Top Left */
@mixin curl-top-left {
	@include hacks();
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		content: '';
		height: 0;
		width: 0;
		top: 0;
		left: 0;
		background: $revealAreaColor; /* IE9 */
		background: linear-gradient(
			135deg,
			$revealAreaColor 45%,
			$curlLineColor 50%,
			$curlTransitionColor 56%,
			$curlLastColor 80%
		);
		filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#000000');/*For IE7-8-9*/

		z-index: 1000;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, .4);
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, "width, height");
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		width: $curlWidth;
		height: $curlHeight;
	}
}
