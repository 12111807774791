.language-container {
    position: absolute;
    top: 13px;
    right: 1%;

    @include respond-to($navbar-collapse-at) {
        right: auto;
        left: 5px;
    }

    .language-switcher {
        position: relative;


        li {
            display: inline-block;

            &.wow {
                display: none;
            }

            a {
                padding: 0px 3px;

            }
        }
    }
}




//@media (min-width: 768px) and (max-width: 890px){
//    .language-li {
//        width: 70px;
//    }
//    .language-switcher {
//        width: 150px;
//        left: -52px;
//        top: 10px;
//
//        li {
//            height: 12px;
//            a {
//                display: inline-block;
//                font-size: 12px;
//                height: 12px;
//                padding: 0px 0px;
//            }
//        }
//    }
//}
//
//@media (min-width: 891px) and (max-width: 991px){
//    .language-switcher {
//        left: 0px;
//        top: 3px;
//        li a {
//            padding: 3px 6px;
//        }
//    }
//}
//
//@media (min-width: 992px) and (max-width: 1199px){
//    .language-switcher {
//        left: 0px;
//        top: 3px;
//        li a {
//            padding: 3px 0px;
//        }
//    }
//}
//
//
//
//@media (min-width: 1260px){
//    .language-switcher {
//        left: 138px;
//        top: 3px;
//        li a {
//            padding: 3px 6px;
//        }
//    }
//}


@media (min-width: $navbar-collapse-at) {
    .navbar {
        height: 60px;

        .navbar-nav {
            display: inline-block;
            float: none;

            font-family: "Fira Sans",sans-serif;
            font-size: 1.2em;
            font-weight: 400;
            color: #c2aa60;
        }

        .navbar-collapse {
            text-align: center;


            &>ul {
                position: relative;
                //left: -5.33%;
                top: 7px;

                &>li {
                    &>a {
                        padding: 15px 20px;
                    }
                }
            }
        }



    }


}

