/* Icon Bounce */

@mixin icon-bounce {
	@include hacks();
	position: relative;
	padding-right: #{$spaceBetweenTextAndArrows};
	@include prefixed(transition-duration, $mediumDuration);

	&:before {
		content: "\f087";
		position: absolute;
		right: 1em;
		padding: 0 1px;
		font-family: FontAwesome;
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		&:before {
			@include prefixed(transform, scale(1.5));
			@include prefixed(transition-timing-function, cubic-bezier(.47,2.02,.31,-0.36));
		}
	}
}
