.front-highlight {
    height: calc(100vh - 60px);
    background: url(/img/pages/top-bg.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;

    display: flex;
    flex-direction: column;

    @include respond-to(600px) {
        height: calc(84vh - 60px);
    }

    .logo {

        img {

        margin: 0 auto;
            height: 24vh;
        }
    }



    .members-photo {
        padding-top: 25px;
        flex-grow: 3;
        align-self: center;
        position: relative;


        height:100%;
        width:100%;
        margin-top: 25px;

        .member-photo {

            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;

            @include prefixed(transition, opacity 1s ease);


        }

        #member-photo-1 {
            background: url(/img/front_slide/top-band-slide-1.png) no-repeat center bottom;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain;

        }
        #member-photo-2 {
            background: url(/img/front_slide/top-band-slide-2.png) no-repeat center bottom;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain;

        }
        #member-photo-3 {
            background: url(/img/front_slide/top-band-slide-3.png) no-repeat center bottom;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain;

        }
        #member-photo-4 {
            background: url(/img/front_slide/top-band-slide-4.png) no-repeat center bottom;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain;

        }
        #member-photo-4 {
            background: url(/img/front_slide/top-band-slide-5.png) no-repeat center bottom;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain;

        }

    }

    .front-smoke {
        position: absolute;
        bottom: 0px;
        width: 100%;

        background: url(/img/pages/top-smoke-bg.png) repeat-x bottom center;
        background-size: cover;
        min-height: 585px;
        background-position-x: 0px;
    }

    .scroll-down-container {
        position: absolute;
        bottom: 94px;
        left: 50%;
        width: 100px;
        margin-left: -50px;
        cursor: pointer;

        @include respond-to(md) {
            width: 80px;
            margin-left: -40px;
        }

        @include respond-to(sm) {
            width: 60px;
            margin-left: -30px;
        }

        @include respond-to(xs) {
            width: 60px;
            margin-left: -30px;
        }
    }
}

.oceneni {

    position: absolute;
    right: 30px;
    top: -245px;
    width: 300px;

    .oceneni-text {
        position: absolute;
        top: 3px;
        left: 23px;
        color: black;
        font-style: italic;
        font-size: 1.1em;
        font-weight: 300;
        width: 60%;
        border-bottom: 1px solid black;

        h3 {

            font-weight: 600;
            font-style: italic;
            font-size: 1.2em;
            margin-bottom: 5px;
        }
    }

    @include respond-to(md) {
        width: 300px;

    }

    @include respond-to(sm) {
        width: 280px;
        font-size: 0.9em;

    }

    @include respond-to(xs) {
        display: none;

    }
}

.promo-box {
    position: relative;
    margin-bottom: -20px;

    img {
        width: 100%;
    }

    @include respond-to(sm) {
        top: 0px;
        margin-bottom: 0px;
    }

    @include respond-to(xs) {
        top: 0px;
        margin-bottom: 0px;
    }
}

.left-home-col {
    position:relative;
    top: -200px;
}

.tour-box {
    position: relative;
    margin-bottom: 20px;
}

.news-box {
    position: relative;
    top: -35px;
    margin-bottom: -19px;

    @include respond-to(sm) {
        top: 0px;
        margin-bottom: 0px;
    }

    @include respond-to(xs) {
        top: 0px;
        margin-bottom: 0px;
    }
}

.booking {
    background: url(/img/elements/booking-bg.png) no-repeat top left;
    background-size: contain;
    min-height: 750px;

    .booking-title {
        font-size: 2em;
        font-weight: 600;
    }

    .booking-text {
        margin-left: 126px;
        padding-top: 37px;
        color: $gold;

        @include respond-to(md) {
            margin-left: 130px;
        }

        @include respond-to(500px) {
            margin-left: 27%;
        }

        @include respond-to(385px) {
            font-size: 0.8em;
        }



        .booking-button button {

            padding: 12px 17px;
            background-color: $gold;
            background: url(/img/elements/titles-bg.jpg);
            background-size: cover;
            border: 0px;
            color: black;
            margin-top: 15px;
            cursor: pointer;
        }

    }
}

.welcome-box {

    font-style: italic;
    color: white;
    font-size: 0.9em;

    margin-left: 115px;
    margin-top: 340px;

    @include respond-to(lg) {
        margin-right: 100px;
    }

    @include respond-to(md) {
        margin-left: 110px;
        margin-top: 340px;

    }

    @include respond-to(500px) {
        margin-left: 27%;
    }

    @include respond-to(385px) {
        font-size: 0.8em;
        margin-top: 230px;
    }

    h1, h2 {
        color: $gold;
        font-weight: 600;

        font-size: 1.8em;
    }
}

.welcome-logo {
    margin-left: 83px;
    margin-top: 23px;

    @include respond-to(md) {
        margin-left: 41px;
    }

    @include respond-to(500px) {
        margin-left: 0;
    }
}

.home-profile {

    .profile-title {

        position: absolute;
        background-color: $gold;
        background: url(/img/elements/titles-bg.jpg);
        background-size: cover;
        padding: 7px 78px 7px 32px;
        font-weight: 300;
        font-size: 1.6em;
        left: 17px;
        top: -19px;
    }

    .profile-button {
        position: absolute;
        background-color: $gold;
        background: url(/img/elements/titles-bg.jpg);
        background-size: cover;
        padding: 7px 40px 7px 40px;
        font-weight: 300;
        font-size: 1.6em;
        right: 17px;
        bottom: -19px;
        cursor: pointer;
    }

    .profile-text {
        position: absolute;
        width: 39%;
        right: 28px;
        color: $light-gold;
        top: 92px;
        font-weight: 300;

        @include respond-to(md) {
            top: 20px;
        }

        @include respond-to(sm) {
            top: 20px;
            font-size: 0.8em;
        }

        @include respond-to(xs) {
            top: 20px;
            width: 67%;
            font-size: 0.8em;
        }

        @include respond-to(650px) {
            top: 30px;
            width: 93%;
            font-size: 0.8em;
        }
    }

    @include respond-to(550px) {

        .profile-image {
            display: none;
        }

        .profile-text {
            position: relative;
            right: inherit;
            top: 40px;
            padding-left: 10px;
            font-size: 1em;

        }

        .profile-button {
            position:relative;
            margin-top: 20px;
            left: 10px;
            right: inherit;
        }
    }

}