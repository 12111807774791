#float-cart-container {
    position:absolute;
    top: 130px;
    right: 2%;
    width: 100%;

    .ajax-loader {
        opacity: 0;
    }

    #cart-overview {
        display: none;
        position: relative;
        width: 73%;
        float: right;
        clear: both;
        bottom: 0px;
        background-color: rgba(4, 4, 4, 0.92);
        color: $light-gold;
        border: 1px solid darken($gold, 30%);

        border-radius: 0px 0px 10px 10px;

        -webkit-box-shadow: 0px 4px 28px 12px rgba(232,207,166,1);
        -moz-box-shadow: 0px 4px 28px 12px rgba(232,207,166,1);
        box-shadow: 0px 4px 28px 12px rgba(232,207,166,1);

        padding: 15px;
        z-index: 1002;
        @include prefixed('box-shadow', '10px 10px 9px rgba(0,0,0,0.14)');

        @media screen and (max-width: $screen-md) {
            width: 100%;

        }


        h3 {
            color: $red;
            font-weight: 400;
        }

        hr {
            background-color: $darkgray;
            height: 1px;
        }

        #cart-empty {
            display: none;
            text-align: center;
            width: 100%;
            margin-top: 30px;
            margin-bottom: 30px;
            font-weight: 200;
            font-size: 1.3em;
            color: $darkgray;
        }

        #cart-items {
            width: 98%;
            margin: 0 auto;

            th {
                border-bottom: 1px solid $darkgray;
                color: $red;
                font-size: 0.9em;
                font-weight: 400;

                &.remove-th {
                    text-align: center;
                }
            }


            .cart-update-qty {
                width: 40px;
                border: 0px;
                text-align: center;
            }



            td {
                border-bottom: 1px solid $darkgray;
                font-size: 0.95em;
                padding: 2px 0px;


                &.remove-td {
                    text-align: center;
                    color: $red;
                }
            }

            .cart-img {
                max-height: 50px;
            }

            .cart-remove-item {
                cursor: pointer;
            }

            .cart-update-qty {


                text-align: center;
                border: 1px solid $darkgray !important;
                width: 35px;
                background-color: black;
                color: $light-gold;
            }


        }

        #cart-total {
            width: 95%;
            margin: 0 auto;
            text-align: right;
            color: $red;
            font-weight: 400;
            font-size: 1.1em;
        }

    }
}

#search-bar {
    padding-left: 5px;
    float: right;
    height: 35px;
    margin-right: 30px;
    background-color: #fff;
    border-bottom: 1px solid $lightgray;
    border-left: 1px solid $lightgray;
    border-right: 1px solid $lightgray;

    form {
        display: inline-block;
    }
    input {
        padding-left: 5px;
        border: 0px;

    }
}
#cart-btn {

    cursor: pointer;
    position: relative;

    #cart-btn-qty {
        position: absolute;
        top: -7px;
        color: white;
        right: -10px;
        background-color: red;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        text-align: center;
        padding-top: 2px;
        opacity: 0;

        @include prefixed(transition, opacity 1s ease);

        &.active {
            opacity: 1;
        }
    }






}
.cart-btn-effect {
    height: 35px;

    position: relative;

}

.cart-inside-buttons {
    width: 100%;
    padding-top: 10px;
    padding-right: 5px;

    #checkout-btn {
        float: right;
    }
}


#cart-container {


    #cart-overview {
        position: relative;

        color: $light-gold;

        margin-top: 30px;


        h3 {
            color: $red;
            font-weight: 400;
        }

        hr {
            background-color: $darkgray;
            height: 1px;
        }

        #cart-empty {
            display: none;
            text-align: center;
            width: 100%;
            margin-top: 30px;
            margin-bottom: 30px;
            font-weight: 200;
            font-size: 1.3em;
            color: $darkgray;
        }

        #cart-items {
            width: 98%;
            margin: 0 auto;

            th {
                border-bottom: 1px solid $darkgray;
                color: $red;
                font-size: 0.9em;
                font-weight: 400;

                &.remove-th {
                    text-align: center;
                }
            }


            .cart-update-qty {
                width: 40px;
                border: 0px;
                text-align: center;
            }



            td {
                border-bottom: 1px solid $darkgray;
                font-size: 0.95em;
                padding: 2px 0px;


                &.remove-td {
                    text-align: center;
                    color: $red;
                }
            }

            .cart-img {
                max-height: 50px;
            }

            .cart-remove-item {
                cursor: pointer;
            }

            .cart-update-qty {


                text-align: center;
                border: 1px solid $darkgray !important;
                width: 35px;
                background-color: black;
                color: $light-gold;
            }


        }

        #cart-total {
            width: 95%;
            margin: 0 auto;
            text-align: right;
            color: $red;
            font-weight: 400;
            font-size: 1.1em;
        }

    }
}
