/* Wobble To Top Right */
@include keyframes(#{$nameSpace}-wobble-to-top-right) {
  16.65% {
    @include prefixed(transform, translate(8px, -8px));
  }

  33.3% {
    @include prefixed(transform, translate(-6px, 6px));
  }

  49.95% {
    @include prefixed(transform, translate(4px, -4px));
  }

  66.6% {
    @include prefixed(transform, translate(-2px, 2px));
  }

  83.25% {
    @include prefixed(transform, translate(1px, -1px));
  }

  100% {
    @include prefixed(transform, translate(0, 0));
  }
}

@mixin wobble-to-top-right {
	@include hacks();

	&:hover,
    &:focus,
    &:active {
        @include prefixed(animation-name, #{$nameSpace}-wobble-to-top-right);
        @include prefixed(animation-duration, 1s);
        @include prefixed(animation-timing-function, ease-in-out);
        @include prefixed(animation-iteration-count, 1);
	}
}
