.account-menu {
    .divider {
        margin-top: 10px;
    }
    

}

.ship-addr-delete {
    color: $red;
    margin-left: 10px;
    cursor: pointer;
}

#account-order-list-div {
    .order-list {
        width: 100%;

        th {
            font-weight: 600;
            font-size: 0.9em;
            border-bottom: 2px solid $lightgray;
        }

        tr {
            border-bottom: 1px solid $lightgray;

            td {
                font-size: 0.9em;
            }

        }
    }
}

.order-detail-title {
    font-weight: 600;
    font-size: 1.3em;

    padding: 10px;
}

.order-detail-title-right {
    text-align: right;
    font-weight: 600;
    font-size: 0.95em;

}

.account-menu {
    ul {
        list-style: none;
        color: black;
        padding-left: 0px;
        margin-top: 25px;

        li {
            background-color: $gold;

            margin-bottom: 2px;
            @include prefixed(transition, background-color 0.3s ease);

            &:hover {
                background-color: darken($gold, 10%);
            }

            a {
                display: block;
                padding: 8px 15px;
            }
        }
    }
}
.no-orders {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
}