/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 1.25em; height: 1.25em;
    border: 2px solid $gold;
    background: black;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: '✔';
    position: absolute;
    top: .1em; left: .2em;
    font-size: 1.3em;
    line-height: 0.8;
    color: #09ad7e;
    transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}
[type="checkbox"]:disabled + label {
    color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    border: 2px solid $gold;
}

/* hover style just for information */
label:hover:before {
    border: 2px solid $light-gold!important;
}

