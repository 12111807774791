//@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,400i,600,600i');
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic&subset=latin,latin-ext");


@import "partials/variables";
@import "partials/loader";
@import "bootstrap_theme/bootstrap";
@import "font-awesome";
@import "sweetalert";
@import "hover/hover";
@import "slick/slick";
@import "slick/slick-theme";
@import "select2/core";
@import "partials/checkboxes";

@import "partials/labelauty";
@import "partials/mixins";


@import "partials/base";
@import "partials/objects";
@import "partials/navbar";
@import "partials/homepage";
@import "partials/otherpages";

// ESHOP

@import "eshop/colors";
@import "eshop/mixins";
@import "eshop/pace";
@import "eshop/account";
@import "eshop/cart";
@import "eshop/checkout";
@import "eshop/items";
@import "eshop/registration";


