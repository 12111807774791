/* Icon Up */
@include keyframes(#{$nameSpace}-icon-up) {
	0%,
	50%,
	100% {
		@include prefixed(transform, translateY(0));
	}
	25%,
	75% {
		@include prefixed(transform, translateY(-6px));
	}
}

/* Icon Up */
@mixin icon-up {
	@include hacks();
	position: relative;
	padding-right: 2.2em;

	&:before {
		content: "\f01b";
		position: absolute;
		right: 1em;
		padding: 0 1px;
		font-family: FontAwesome;
		@include prefixed(transform, translateZ(0));
	}

	&:hover,
	&:focus,
	&:active {

		&:before {
			@include prefixed(animation-name, #{$nameSpace}-icon-up);
			@include prefixed(animation-duration, .75s);
			@include prefixed(animation-timing-function, ease-out);
		}
	}
}
